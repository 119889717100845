import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import SEO from './seo'

const Layout = ({ seoTitle, children, bodyClassName }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query SiteDataQuery {
      wp {
        generalSettings {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO title={seoTitle} />

      <div className='layout is-flex is-flex-direction-column'>
        <Header />

        <main
          className={`is-flex-grow-1 ${bodyClassName ? bodyClassName : ''}`}
        >
          {children}
        </main>

        <footer className='footer'>
          <div className='container has-text-centered'>
            {`© ${new Date().getFullYear()} ${title}`}
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string.isRequired,
}

export default Layout
