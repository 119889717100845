import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Helmet from 'react-helmet'

const Header = () => {
  const {
    wp: {
      generalSettings: { title, siteLogoUrl },
    },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      wp {
        generalSettings {
          title
          siteLogoUrl
        }
      }
    }
  `)

  const menuItems = [
    {
      name: 'About',
      slug: 'about',
    },
    {
      name: 'Gallery',
      slug: 'gallery',
    },
    {
      name: 'Contact',
      slug: 'contact',
    },
  ]

  const [toggleNavbar, setToggleNavbar] = useState(false)

  const handleNavbarMenuClick = e => {
    setToggleNavbar(!toggleNavbar)
  }

  return (
    <>
      <Helmet htmlAttributes={{ class: 'has-navbar-fixed-top' }} />
      <nav className='navbar is-dark is-fixed-top'>
        <div className='navbar-brand'>
          <div className='navbar-item'>
            <Link to='/'>
              <div className='is-flex'>
                <img src={siteLogoUrl} alt={'logo'} />
                <span className='has-hero-font ml-2 has-text-light'>
                  {title}
                </span>
              </div>
            </Link>
          </div>

          <a
            className={`navbar-burger ${toggleNavbar && 'is-active'}`}
            onClick={handleNavbarMenuClick}
          >
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>
        <div className={`navbar-menu ${toggleNavbar && 'is-active'}`}>
          <div className='navbar-start'>
            {menuItems.map(item => {
              return (
                <Link
                  to={`/${item.slug}`}
                  className='navbar-item is-tab'
                  activeClassName={'is-active'}
                  key={item.slug}
                >
                  {item.name}
                </Link>
              )
            })}
          </div>
          <div className='navbar-end'>
            <div className='navbar-item'>
              <Link className='button is-primary' to='/contact'>
                Order Now!
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
